import { Pipe, PipeTransform } from '@angular/core';
import * as utility from 'src/app/utils/utility-methods';

export type ImageQualityType = 'minimum' | 'low' | 'medium' | 'high';
@Pipe({
   name: 'imageQuality',
   standalone: true
})
export class ImageQualityPipe implements PipeTransform {
   transform(imageURL: string | undefined | null, quality: ImageQualityType): string | undefined {
      if (!imageURL) {
         return undefined;
      }

      if (typeof imageURL === 'object') {
         return imageURL;
      }

      if (quality === 'low' && utility.checkFileFormat(imageURL) === 'video') {
         return imageURL;
      }

      switch (quality) {
         case 'minimum':
            return utility.getMinimumQualityImageBasedOnURL(imageURL);
         case 'low':
            return utility.getLowQualityImageBasedOnURL(imageURL);
         case 'medium':
            return utility.getMediumQualityImageBasedOnURL(imageURL);
         case 'high':
            return utility.getHighQualityImageBasedOnURL(imageURL);
         default:
            return imageURL;
      }
   }
}
